<template>
<div class="certification_company">
  <div class="from-box">

    <p class="title">企业信息</p>
    <p class="info">企业证件信息仅用于企业认证，不会泄露您的任何证件信息</p>

    <div class="from-content">

    <van-form validate-first class="from" ref="ruleForm">

      <van-field
        required
        input-align="right"
        error-message-align="right"
        size="large"
        v-model="from.companyName"
        label="企业名称"
        name="companyName"
        placeholder="请输入企业名称"
        maxlength="20"
        :rules=rules.companyName
      />

      <div class="text">
        <van-field
          required
          input-align="right"
          error-message-align="right"
          size="large"
          label="统一社会信用代码"
          readonly
          label-width="200"
          :border="false"
          class="file-text"
        />
        <van-field
          class="file-val"
          size="large"
          v-model="from.socialCreditCode"
          name="socialCreditCode"
          placeholder="请输入统一社会信用代码"
          maxlength="20"
          :disabled="from.verifyStatus && from.verifyStatus=='3'"
          :rules=rules.socialCreditCode
        />
      </div>

      <div class="img-box">

        <van-field
          required
          input-align="right"
          error-message-align="right"
          size="large"
          label="营业执照"
          readonly
          label-width="150"
          :border="false"
          class="file-text"
        />


        <p>图片格式仅限jpg、png格式，大小不超过3M</p>

        <van-field
          name="businessLicenceAttmId"
          :rules=rules.businessLicenceAttmId
          class="uploader">
          <template #input>
            <verified-upload
              :accept="accept"
              :maxsize="3"
              class="field-img"
              v-model="from.businessLicenceAttmId"
              fileTypeCode="1012"
              :bg=bg
            ></verified-upload>
          </template>
        </van-field>


      </div>
    </van-form>

    </div>
  </div>

  <div class="btn">
    <van-button class="btn-left" color="#EA0B06" block @click="handleSubmit">下一步</van-button>
  </div>
</div>
</template>

<script>
import verified_upload from '@/page/verified/components/verified_upload'
import { getH5CurrentCompany} from "@/api/res-company";
import {Toast} from "vant";
import { mapState, mapMutations  } from 'vuex'
export default {
  name: 'certification_company',
  components:{
    'verified-upload':verified_upload
  },
  data () {
    return {
      company:null,
      bg:require('@/assets/img/company1.png'),
      accept:'.jpg,.png',
      from: {
        companyName:"",
        socialCreditCode:'',
        businessLicenceAttmId:'',
      },
      rules:{
        companyName: [
          {
            required: true,
            trigger: "onBlur",
          },
        ],
        socialCreditCode: [
          {
            required: true,
            trigger: "onBlur",
          },
        ],
        businessLicenceAttmId:[
          {
            required: true,
            message: "请上传营业执照",
            trigger: "onBlur",
          },
        ],
      }
    }
  },
  computed:{
    ...mapState(['infoList'])
  },
  mounted() {
    document.title = '企业认证'
  },

  beforeRouteEnter(to, from, next){
   next((vm)=>{
     if (from.name === "uerinfo_company") {
       vm.from=vm.$store.state.infoList
     }else {
       vm.loadCompany()
     }
   })
  },

  methods:{
    ...mapMutations(['set_infoList']),
    loadCompany() {
      Toast.loading({
        message: '加载中',
        forbidClick: true,
      });
      getH5CurrentCompany().then((company) => {
         /*  for(let i in company) {
            let exist = this.from.hasOwnProperty(i)
            if (exist) {
              this.from[i] = company[i]
            }
          } */
        this.from=company


        Toast.clear()
      });
    },


    handleSubmit(){
      console.log(this.from,'infoList---')
      this.set_infoList(this.from)
      console.log(this.infoList,'infoList')
      this.$refs.ruleForm.validate().then(()=>{
        this.$router.push({
          path:'/verified/uerinfo_company'
         /*  query:this.from */
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.certification_company{
  background: #F6F6F6;
  width: 100vw;
  min-height: calc(100vh - 56px);
  .from-box{
    width: 100vw;
    padding: 16px;
    ::v-deep{
      .van-cell__title{
        font-size: 16px;
        //font-weight: 500;
        color: #333333;
      }
      .van-cell__value{
        font-size: 16px;
        //font-weight: 500;
        color: #999999;
      }
      .van-cell{
        padding-right: 8px;
        &::after{
          position: absolute;
          box-sizing: border-box;
          content: ' ';
          pointer-events: none;
          right: 8px;
          bottom: 0;
          left: 8px;
          border-bottom: 1px solid #ebedf0;
        }
      }
    }

    >.title{
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    >.info{
      font-size: 12px;
      //font-weight: 500;
      color: #999999;
      margin: 5px 0 16px 0;
    }
    >.from-content{
      padding: 12px 0;
      background: #FFFFFF;
      border-radius: 4px;
    }

    .text{
      background: #FFFFFF;
      >p{
        font-size: 16px;
        //font-weight: 500;
        color: #333333;
        padding: 18px 0 12px 0;
      }
      >p:before{
        content: '* ';
        color: #EA0B06;
      }
      &::v-deep{
        .van-cell{
          background: #F6F6F6;
        }
      }
    }

    .img-box{
      &>:nth-child(1){
        margin-top: 6px;
      }
      >p:nth-child(2){
        font-size: 14px;
        //font-weight: 500;
        color: #333333;
        margin: 0 8px 16px 8px;
      }
      &::v-deep{
        .van-cell{
          width: auto;
        }
      }
    }
  }

  .uploader{
    padding: 0;
    margin: 0 8px;
    &::v-deep{
      .van-field__control{
        display: block !important;
      }
    }
  }

  .file-text{
    background: #FFFFFF !important;
  }

  .file-val{
    margin: 0  8px;
    width: auto;
  }

  .field-img{
    &::v-deep{
      img{
        width: 60px;
        height: 60px
      }
      .van-uploader__wrapper{
        width: 60px;
        height: 60px
      }
      .van-uploader__preview-image{
        width: 60px;
        height: 60px
      }

    }
  }

  .btn{
    position: fixed;
    left: 16px;
    right: 16px;
    bottom: 0px;
  }

}

</style>
