<template>
  <van-uploader
    class="verified-upload"
    v-model="fileList"
    :before-read="beforeRead"
    :after-read="afterRead"
    :accept=accept
    :preview-full-image="preview"
    :deletable="deletable"
    :preview-image="previewImg"
    :max-count="1">
    <div class="upload-box">
      <img :src="bg" alt="">
    </div>
  </van-uploader>
</template>

<script>
import {uploadFile} from '@/api/res-user'
import { Toast } from 'vant';
export default {
  name: 'verified_upload',
  model: {
    prop: "value",
    event: "change",
  },
  props:{
    accept:{
      type: String,
    },
    value: {
      type: String,
    },

    bg:{
      type:String,
    },
    maxsize:{
      type:Number,
      required: true,
    },
    fileTypeCode: {
      type: String,
      required: true,
    },
    preview:{
      type: Boolean,
      default: true
    },
    deletable:{
      type: Boolean,
      default: true
    },
    previewImg:{
      type:Boolean,
      default: true
    }


  },
  data () {
    return {
      tmpImgName:'',
      fileList:[],

    }
  },
  computed:{
    headers() {
      const token = storage.get(ACCESS_TOKEN);
      return {
        Authorization: `Bearer ${token}`,
      };
    },


  },
  watch:{
    value(data){
      if(this.fileList.length===0){
        this.fileList=[
          {url:`${process.env.VUE_APP_API_PORTAL_URL}/file/image/${data}`,
            isImage: true
          }
        ]
      }
      if(!data){
        this.fileList=[]
      }
    }



  },
  methods: {

    beforeRead(file){
      /* let type=['jpg','jpeg','bmp','png','pdf']
      let typebool=[]
      type.forEach(item=>{
        let isType=file.type.indexOf(item) !== -1
        typebool.push(isType)
      })
      console.log(typebool,'typebool')
      const fileType=typebool.some(item=>{
       return  item===true
      })
      console.log(fileType,'fileType') */
      const isImage = file.type.indexOf("image") !== -1;
      if(!isImage){
        Toast('只能上传图片!');
      }
      const isLt10M = file.size / 1024 / 1024 < this.maxsize;
      if (!isLt10M) {
        Toast(`文件不能超过${this.maxsize}MB!`);
      }
      return isImage && isLt10M;
    },


    afterRead(file) {
      // 此时可以自行将文件上传至服务器


      this.tmpImgName=file.file.name

      const data=this.dataURLtoFile(file.content,this.tmpImgName)

      this.handleUpload(data)


    },


    async handleUpload(file){
      const formData = new FormData()
      formData.append('file', file)
      this.loading = true
      const res = await uploadFile(this.fileTypeCode, formData)
      this.$emit("input", res.id);
      this.$emit("change", res.id);
    },



    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    }

  },
}
</script>

<style scoped lang="scss">
.verified-upload{
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
  margin: 0 auto;
  //::v-deep img{
  //  width: 263px;
  //  height: 155px
  //}
}
</style>
